"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapTypeToComponent = exports.bundleDocument = exports.bundleFromString = exports.bundle = exports.bundleConfig = exports.OasVersion = void 0;
const isEqual = require("lodash.isequal");
const resolve_1 = require("./resolve");
const visitors_1 = require("./visitors");
const types_1 = require("./types");
const walk_1 = require("./walk");
const oas_types_1 = require("./oas-types");
const ref_utils_1 = require("./ref-utils");
const rules_1 = require("./config/rules");
const no_unresolved_refs_1 = require("./rules/no-unresolved-refs");
const utils_1 = require("./utils");
const redocly_1 = require("./redocly");
const remove_unused_components_1 = require("./decorators/oas2/remove-unused-components");
const remove_unused_components_2 = require("./decorators/oas3/remove-unused-components");
const redocly_yaml_1 = require("./types/redocly-yaml");
var OasVersion;
(function (OasVersion) {
    OasVersion["Version2"] = "oas2";
    OasVersion["Version3_0"] = "oas3_0";
    OasVersion["Version3_1"] = "oas3_1";
})(OasVersion || (exports.OasVersion = OasVersion = {}));
function bundleConfig(document, resolvedRefMap) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const types = (0, types_1.normalizeTypes)(redocly_yaml_1.ConfigTypes);
        const ctx = {
            problems: [],
            oasVersion: oas_types_1.SpecVersion.OAS3_0,
            refTypes: new Map(),
            visitorsData: {},
        };
        const bundleVisitor = (0, visitors_1.normalizeVisitors)([
            {
                severity: 'error',
                ruleId: 'configBundler',
                visitor: {
                    ref: {
                        leave(node, ctx, resolved) {
                            replaceRef(node, resolved, ctx);
                        },
                    },
                },
            },
        ], types);
        (0, walk_1.walkDocument)({
            document,
            rootType: types.ConfigRoot,
            normalizedVisitors: bundleVisitor,
            resolvedRefMap,
            ctx,
        });
        return (_a = document.parsed) !== null && _a !== void 0 ? _a : {};
    });
}
exports.bundleConfig = bundleConfig;
function bundle(opts) {
    return __awaiter(this, void 0, void 0, function* () {
        const { ref, doc, externalRefResolver = new resolve_1.BaseResolver(opts.config.resolve), base = null, } = opts;
        if (!(ref || doc)) {
            throw new Error('Document or reference is required.\n');
        }
        const document = doc === undefined ? yield externalRefResolver.resolveDocument(base, ref, true) : doc;
        if (document instanceof Error) {
            throw document;
        }
        return bundleDocument(Object.assign(Object.assign({ document }, opts), { config: opts.config.styleguide, externalRefResolver }));
    });
}
exports.bundle = bundle;
function bundleFromString(opts) {
    return __awaiter(this, void 0, void 0, function* () {
        const { source, absoluteRef, externalRefResolver = new resolve_1.BaseResolver(opts.config.resolve) } = opts;
        const document = (0, resolve_1.makeDocumentFromString)(source, absoluteRef || '/');
        return bundleDocument(Object.assign(Object.assign({ document }, opts), { externalRefResolver, config: opts.config.styleguide }));
    });
}
exports.bundleFromString = bundleFromString;
function bundleDocument(opts) {
    return __awaiter(this, void 0, void 0, function* () {
        const { document, config, customTypes, externalRefResolver, dereference = false, skipRedoclyRegistryRefs = false, removeUnusedComponents = false, keepUrlRefs = false, } = opts;
        const specVersion = (0, oas_types_1.detectSpec)(document.parsed);
        const specMajorVersion = (0, oas_types_1.getMajorSpecVersion)(specVersion);
        const rules = config.getRulesForOasVersion(specMajorVersion);
        const types = (0, types_1.normalizeTypes)(config.extendTypes(customTypes !== null && customTypes !== void 0 ? customTypes : (0, oas_types_1.getTypes)(specVersion), specVersion), config);
        const preprocessors = (0, rules_1.initRules)(rules, config, 'preprocessors', specVersion);
        const decorators = (0, rules_1.initRules)(rules, config, 'decorators', specVersion);
        const ctx = {
            problems: [],
            oasVersion: specVersion,
            refTypes: new Map(),
            visitorsData: {},
        };
        if (removeUnusedComponents) {
            decorators.push({
                severity: 'error',
                ruleId: 'remove-unused-components',
                visitor: specMajorVersion === oas_types_1.SpecMajorVersion.OAS2
                    ? (0, remove_unused_components_1.RemoveUnusedComponents)({})
                    : (0, remove_unused_components_2.RemoveUnusedComponents)({}),
            });
        }
        let resolvedRefMap = yield (0, resolve_1.resolveDocument)({
            rootDocument: document,
            rootType: types.Root,
            externalRefResolver,
        });
        if (preprocessors.length > 0) {
            // Make additional pass to resolve refs defined in preprocessors.
            (0, walk_1.walkDocument)({
                document,
                rootType: types.Root,
                normalizedVisitors: (0, visitors_1.normalizeVisitors)(preprocessors, types),
                resolvedRefMap,
                ctx,
            });
            resolvedRefMap = yield (0, resolve_1.resolveDocument)({
                rootDocument: document,
                rootType: types.Root,
                externalRefResolver,
            });
        }
        const bundleVisitor = (0, visitors_1.normalizeVisitors)([
            {
                severity: 'error',
                ruleId: 'bundler',
                visitor: makeBundleVisitor(specMajorVersion, dereference, skipRedoclyRegistryRefs, document, resolvedRefMap, keepUrlRefs),
            },
            ...decorators,
        ], types);
        (0, walk_1.walkDocument)({
            document,
            rootType: types.Root,
            normalizedVisitors: bundleVisitor,
            resolvedRefMap,
            ctx,
        });
        return {
            bundle: document,
            problems: ctx.problems.map((problem) => config.addProblemToIgnore(problem)),
            fileDependencies: externalRefResolver.getFiles(),
            rootType: types.Root,
            refTypes: ctx.refTypes,
            visitorsData: ctx.visitorsData,
        };
    });
}
exports.bundleDocument = bundleDocument;
function mapTypeToComponent(typeName, version) {
    switch (version) {
        case oas_types_1.SpecMajorVersion.OAS3:
            switch (typeName) {
                case 'Schema':
                    return 'schemas';
                case 'Parameter':
                    return 'parameters';
                case 'Response':
                    return 'responses';
                case 'Example':
                    return 'examples';
                case 'RequestBody':
                    return 'requestBodies';
                case 'Header':
                    return 'headers';
                case 'SecuritySchema':
                    return 'securitySchemes';
                case 'Link':
                    return 'links';
                case 'Callback':
                    return 'callbacks';
                default:
                    return null;
            }
        case oas_types_1.SpecMajorVersion.OAS2:
            switch (typeName) {
                case 'Schema':
                    return 'definitions';
                case 'Parameter':
                    return 'parameters';
                case 'Response':
                    return 'responses';
                default:
                    return null;
            }
        case oas_types_1.SpecMajorVersion.Async2:
            switch (typeName) {
                case 'Schema':
                    return 'schemas';
                case 'Parameter':
                    return 'parameters';
                default:
                    return null;
            }
    }
}
exports.mapTypeToComponent = mapTypeToComponent;
function replaceRef(ref, resolved, ctx) {
    if (!(0, utils_1.isPlainObject)(resolved.node)) {
        ctx.parent[ctx.key] = resolved.node;
    }
    else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete ref.$ref;
        const obj = Object.assign({}, resolved.node, ref);
        Object.assign(ref, obj); // assign ref itself again so ref fields take precedence
    }
}
// function oas3Move
function makeBundleVisitor(version, dereference, skipRedoclyRegistryRefs, rootDocument, resolvedRefMap, keepUrlRefs) {
    let components;
    let rootLocation;
    const visitor = {
        ref: {
            leave(node, ctx, resolved) {
                if (!resolved.location || resolved.node === undefined) {
                    (0, no_unresolved_refs_1.reportUnresolvedRef)(resolved, ctx.report, ctx.location);
                    return;
                }
                if (resolved.location.source === rootDocument.source &&
                    resolved.location.source === ctx.location.source &&
                    ctx.type.name !== 'scalar' &&
                    !dereference) {
                    return;
                }
                // do not bundle registry URL before push, otherwise we can't record dependencies
                if (skipRedoclyRegistryRefs && (0, redocly_1.isRedoclyRegistryURL)(node.$ref)) {
                    return;
                }
                if (keepUrlRefs && (0, ref_utils_1.isAbsoluteUrl)(node.$ref)) {
                    return;
                }
                const componentType = mapTypeToComponent(ctx.type.name, version);
                if (!componentType) {
                    replaceRef(node, resolved, ctx);
                }
                else {
                    if (dereference) {
                        saveComponent(componentType, resolved, ctx);
                        replaceRef(node, resolved, ctx);
                    }
                    else {
                        node.$ref = saveComponent(componentType, resolved, ctx);
                        resolveBundledComponent(node, resolved, ctx);
                    }
                }
            },
        },
        Root: {
            enter(root, ctx) {
                rootLocation = ctx.location;
                if (version === oas_types_1.SpecMajorVersion.OAS3) {
                    components = root.components = root.components || {};
                }
                else if (version === oas_types_1.SpecMajorVersion.OAS2) {
                    components = root;
                }
            },
        },
    };
    if (version === oas_types_1.SpecMajorVersion.OAS3) {
        visitor.DiscriminatorMapping = {
            leave(mapping, ctx) {
                for (const name of Object.keys(mapping)) {
                    const $ref = mapping[name];
                    const resolved = ctx.resolve({ $ref });
                    if (!resolved.location || resolved.node === undefined) {
                        (0, no_unresolved_refs_1.reportUnresolvedRef)(resolved, ctx.report, ctx.location.child(name));
                        return;
                    }
                    const componentType = mapTypeToComponent('Schema', version);
                    if (dereference) {
                        saveComponent(componentType, resolved, ctx);
                    }
                    else {
                        mapping[name] = saveComponent(componentType, resolved, ctx);
                    }
                }
            },
        };
    }
    function resolveBundledComponent(node, resolved, ctx) {
        const newRefId = (0, resolve_1.makeRefId)(ctx.location.source.absoluteRef, node.$ref);
        resolvedRefMap.set(newRefId, {
            document: rootDocument,
            isRemote: false,
            node: resolved.node,
            nodePointer: node.$ref,
            resolved: true,
        });
    }
    function saveComponent(componentType, target, ctx) {
        components[componentType] = components[componentType] || {};
        const name = getComponentName(target, componentType, ctx);
        components[componentType][name] = target.node;
        if (version === oas_types_1.SpecMajorVersion.OAS3) {
            return `#/components/${componentType}/${name}`;
        }
        else {
            return `#/${componentType}/${name}`;
        }
    }
    function isEqualOrEqualRef(node, target, ctx) {
        var _a;
        if ((0, ref_utils_1.isRef)(node) &&
            ((_a = ctx.resolve(node, rootLocation.absolutePointer).location) === null || _a === void 0 ? void 0 : _a.absolutePointer) ===
                target.location.absolutePointer) {
            return true;
        }
        return isEqual(node, target.node);
    }
    function getComponentName(target, componentType, ctx) {
        const [fileRef, pointer] = [target.location.source.absoluteRef, target.location.pointer];
        const componentsGroup = components[componentType];
        let name = '';
        const refParts = pointer.slice(2).split('/').filter(utils_1.isTruthy); // slice(2) removes "#/"
        while (refParts.length > 0) {
            name = refParts.pop() + (name ? `-${name}` : '');
            if (!componentsGroup ||
                !componentsGroup[name] ||
                isEqualOrEqualRef(componentsGroup[name], target, ctx)) {
                return name;
            }
        }
        name = (0, ref_utils_1.refBaseName)(fileRef) + (name ? `_${name}` : '');
        if (!componentsGroup[name] || isEqualOrEqualRef(componentsGroup[name], target, ctx)) {
            return name;
        }
        const prevName = name;
        let serialId = 2;
        while (componentsGroup[name] && !isEqualOrEqualRef(componentsGroup[name], target, ctx)) {
            name = `${prevName}-${serialId}`;
            serialId++;
        }
        if (!componentsGroup[name]) {
            ctx.report({
                message: `Two schemas are referenced with the same name but different content. Renamed ${prevName} to ${name}.`,
                location: ctx.location,
                forceSeverity: 'warn',
            });
        }
        return name;
    }
    return visitor;
}
