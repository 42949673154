import type { Node } from '@markdoc/markdoc/dist/src/types';
import type { LayoutVariant, REDOCLY_ROUTE_RBAC, REDOCLY_TEAMS_RBAC } from './constants';
import type {
  ProductConfig,
  ProductGoogleAnalyticsConfig,
  RbacScopeItems,
  SeoConfig,
  ThemeConfig,
} from './types';

export type ThemeUIConfig = ThemeConfig & {
  auth?: {
    // used by portal dev login emulator
    idpsInfo?: {
      idpId: string;
      type: string; // AuthProviderType
      title: string | undefined;
    }[];
    devLogin?: boolean;
    loginUrls?: Record<string, string>;
  };
  search?: {
    shortcuts?: string[];
    suggestedPages?: any[];
  };
  breadcrumbs?: {
    prefixItems?: ResolvedNavLinkItem[];
  };
  products?: {
    [key: string]: ProductUiConfig;
  };
};

export type ResolvedNavLinkItem = {
  type: 'link';
  fsPath?: string;
  metadata?: Record<string, unknown>;
  link: string;
  label: string;
  labelTranslationKey?: string;
  items?: ResolvedNavItem[];
  sidebar?: ResolvedNavItem[]; // for API catalog it contains the corresponding sidebar first item
  external?: boolean;
  target?: '_self' | '_blank';

  version?: string;
  isDefault?: boolean;
  versionFolderId?: string;

  httpVerb?: string;
  separatorLine?: boolean;
  routeSlug?: string;
  active?: boolean;
  icon?: string;
  srcSet?: string;
  [REDOCLY_TEAMS_RBAC]?: RbacScopeItems;
  [REDOCLY_ROUTE_RBAC]?: {
    slug?: string;
    fsPath?: string;
  };

  linkedSidebars?: string[];
  languageInsensitive?: boolean;
};

export type ResolvedNavGroupItem = {
  type: 'group';
  fsPath?: string;
  metadata?: Record<string, unknown>;
  link?: string;
  label?: string;
  labelTranslationKey?: string;
  items?: ResolvedNavItem[];
  sidebar?: ResolvedNavItem[]; // for API catalog it contains the corresponding sidebar first item
  external?: boolean;
  target?: '_self' | '_blank';
  expanded?: string;
  selectFirstItemOnExpand?: boolean;

  version?: string;
  isDefault?: boolean;
  versionFolderId?: string;

  menuStyle?: MenuStyle;
  separatorLine?: boolean;
  routeSlug?: string;
  active?: boolean;
  icon?: string;
  srcSet?: string;
  [REDOCLY_TEAMS_RBAC]?: RbacScopeItems;
  [REDOCLY_ROUTE_RBAC]?: {
    slug?: string;
    fsPath?: string;
  };

  linkedSidebars?: string[];
  languageInsensitive?: boolean;
};

export type ResolvedNavItem =
  | ResolvedNavLinkItem
  | ResolvedNavGroupItem
  | {
      type: 'separator';
      fsPath?: never;
      metadata?: Record<string, unknown>;
      label?: string;
      labelTranslationKey?: string;
      routeSlug?: never;

      version?: string;
      isDefault?: boolean;
      versionFolderId?: string;

      separatorLine?: boolean;
      linePosition?: 'top' | 'bottom';
      link?: undefined;
      items?: ResolvedNavItem[]; // for typescript
      sidebar?: ResolvedNavItem[]; // for typescript
      linkedSidebars?: string[]; // for typescript
      icon?: string;
      srcSet?: string;
      [REDOCLY_TEAMS_RBAC]?: RbacScopeItems;
      [REDOCLY_ROUTE_RBAC]?: {
        slug?: string;
        fsPath?: string;
      };

      languageInsensitive?: boolean;
    }
  | {
      type: 'error';

      fsPath?: never;
      version?: string;
      isDefault?: boolean;
      versionFolderId?: string;
      metadata?: Record<string, unknown>;
      routeSlug?: never;

      label: string;
      labelTranslationKey?: string;
      link?: undefined;
      items?: ResolvedNavItem[]; // for typescript
      sidebar?: ResolvedNavItem[]; // for typescript
      linkedSidebars?: string[]; // for typescript
      [REDOCLY_TEAMS_RBAC]?: RbacScopeItems;
      [REDOCLY_ROUTE_RBAC]?: {
        slug?: string;
        fsPath?: string;
      };

      icon?: string; // for typescript
      srcSet?: string;
      languageInsensitive?: boolean;
    };

export type ResolvedNavItemWithLink = (ResolvedNavLinkItem | ResolvedNavGroupItem) & {
  link: string;
};

export type ResolvedSidebar = {
  relatedNavbarItem?: Breadcrumb;
  items: ResolvedNavItem[];
};

export interface PageProps {
  metadata?: Record<string, unknown>;
  seo?: SeoConfig;
  frontmatter?: Omit<PageProps, 'frontmatter'> & {
    theme?: any;
    settings?: any;
  };
  disableAutoScroll?: boolean;
  lastModified?: string | null;
  [k: string]: unknown;
  dynamicMarkdocComponents?: string[];
  markdown?: MdOptions;
  openapiOptions?: OpenAPIOptions;
  definitionId?: string;
  variables?: {
    lang?: string;
    rbac?: {
      teams: string[];
    };
  };
}

export interface MdOptions {
  partials: Record<string, Node>;
  variables?: Record<string, any>;
}

export interface PageStaticData {
  props?: PageProps;
  [k: string]: unknown;
}

export type UserData = {
  isAuthenticated: boolean;
  name: string;
  picture: string;
  logoutDisabled?: boolean;
};

export interface PageData {
  templateId: string;
  redirectTo?: string;
  sharedDataIds: Record<string, string>;
  props: PageProps;
  versions?: Version[];
  userData: UserData;
}

export type NavItem = {
  page?: string;
  directory?: string;
  group?: string;
  groupTranslationKey?: string;
  label?: string;
  labelTranslationKey?: string;
  href?: string;
  items?: NavItem[];
  separator?: string;
  separatorTranslationKey?: string;
  separatorLine?: boolean;
  linePosition?: 'top' | 'bottom';
  version?: string;
  menuStyle?: MenuStyle;
  external?: boolean;
  target?: '_self' | '_blank';
  expanded?: boolean | 'always';
  selectFirstItemOnExpand?: boolean;
  flatten?: boolean; // for API catalog only
  icon?: string | { srcSet: string };
  rbac?: RbacScopeItems;
  linkedSidebars?: string[];
  $ref?: string;
  disconnect?: boolean;
};

export interface LogoConfig {
  image?: string;
  srcSet?: string;
  altText?: string;
  link?: string;
  favicon?: string;
}

export type Version = {
  version: string;
  label: string;
  link: string;
  default: boolean;
  active: boolean;
  notExists?: boolean;
  folderId: string;
  [REDOCLY_TEAMS_RBAC]?: RbacScopeItems;
  [REDOCLY_ROUTE_RBAC]?: {
    slug?: string;
    fsPath?: string;
  };
};

export type VersionConfigItem = {
  version: string;
  name?: string;
};

export type VersionsConfigType = {
  versions: VersionConfigItem[];
  default?: string;
};

export type VersionedFolderConfig = {
  // key is a relative path to file inside of a versioned folder, value is an array of versions
  versionedFiles: Map<string, Set<string>>;

  defaultVersion?: string;
  versions: VersionConfigItem[];
  hasVersionsConfig?: boolean; // if versions.yaml file exists
};

export type NavGroup = ResolvedNavItem[] | undefined | string | boolean | number;

export type NavGroupRecord = Record<string, NavGroup>;

export type ResolvedConfigLinks = NavGroup | NavGroupRecord;

export type RawNavConfigItem = NavItem | NavItem[] | string | undefined | boolean | number;

export type RawNavConfig = RawNavConfigItem | Record<string, RawNavConfigItem>;

export type OpenAPIOptions = {
  showRightPanelToggle?: boolean;
  layout?: LayoutVariant;
  collapsedSidebar?: boolean;
};

export type MenuStyle = 'drilldown';

export type Breadcrumb = {
  label: string;
  link?: string;
};

export type ProductThemeOverrideConfig = Pick<
  ThemeUIConfig,
  'logo' | 'navbar' | 'footer' | 'sidebar' | 'search' | 'codeSnippet' | 'breadcrumbs' | 'feedback'
> & { analytics?: { ga?: ProductGoogleAnalyticsConfig } };

export type ProductUiConfig = ProductConfig & {
  slug: string;
  link: string;
  [REDOCLY_TEAMS_RBAC]?: { [key: string]: string };
  [REDOCLY_ROUTE_RBAC]?: { [key: string]: string };
  themeOverride?: ProductThemeOverrideConfig;
};
