import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { RedocStandalone } from 'redoc';

// import * as mock from '../mocks/data.json';
import { Doc, docs } from '../shared/docs';

const App = () => {
	const [spec, setSpec] = React.useState({});
	const [index, setIndex] = React.useState(0);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setLoading(true);

		fetch(`/api/data?index=${index}`)
			.then(r => r.text())
			.then(data => {
				if (data.startsWith('<')) {
					return;
				}

				setSpec(
					JSON.parse(data.replace('/docs/Eon.png', 'https://app.home.eon.com/brand/assets/icons/logo.svg'))
				);
			})
			.finally(() => setLoading(false));
	}, [index]);

	return (
		<>
			<select onChange={e => setIndex(Number(e.target.value))} defaultValue={index} className="spec-select">
				{docs.map((option: Doc, index: number) => (
					<option value={index} key={index}>
						{option.name}
					</option>
				))}
			</select>

			{loading ? null : <RedocStandalone spec={spec} />}
		</>
	);
};

const root = createRoot(document.getElementById('root') || document.createElement('div'));

root.render(<App />);
