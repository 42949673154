"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRedoclyRegistryURL = exports.RedoclyClient = exports.TOKEN_FILENAME = void 0;
const fs_1 = require("fs");
const path_1 = require("path");
const os_1 = require("os");
const registry_api_1 = require("./registry-api");
const config_1 = require("../config/config");
const env_1 = require("../env");
const utils_1 = require("../utils");
const logger_1 = require("../logger");
exports.TOKEN_FILENAME = '.redocly-config.json';
class RedoclyClient {
    constructor(region) {
        this.accessTokens = {};
        this.region = this.loadRegion(region);
        this.loadTokens();
        this.domain = region ? config_1.DOMAINS[region] : env_1.env.REDOCLY_DOMAIN || config_1.DOMAINS[config_1.DEFAULT_REGION];
        env_1.env.REDOCLY_DOMAIN = this.domain; // isRedoclyRegistryURL depends on the value to be set
        this.registryApi = new registry_api_1.RegistryApi(this.accessTokens, this.region);
    }
    loadRegion(region) {
        if (region && !config_1.DOMAINS[region]) {
            throw new Error(`Invalid argument: region in config file.\nGiven: ${logger_1.colorize.green(region)}, choices: "us", "eu".`);
        }
        if (env_1.env.REDOCLY_DOMAIN) {
            return (config_1.AVAILABLE_REGIONS.find((region) => config_1.DOMAINS[region] === env_1.env.REDOCLY_DOMAIN) || config_1.DEFAULT_REGION);
        }
        return region || config_1.DEFAULT_REGION;
    }
    getRegion() {
        return this.region;
    }
    hasTokens() {
        return (0, utils_1.isNotEmptyObject)(this.accessTokens);
    }
    // <backward compatibility: old versions of portal>
    hasToken() {
        return !!this.accessTokens[this.region];
    }
    getAuthorizationHeader() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.accessTokens[this.region];
        });
    }
    // </backward compatibility: portal>
    setAccessTokens(accessTokens) {
        this.accessTokens = accessTokens;
    }
    loadTokens() {
        const credentialsPath = (0, path_1.resolve)((0, os_1.homedir)(), exports.TOKEN_FILENAME);
        const credentials = this.readCredentialsFile(credentialsPath);
        if ((0, utils_1.isNotEmptyObject)(credentials)) {
            this.setAccessTokens(Object.assign(Object.assign({}, credentials), (credentials.token &&
                !credentials[this.region] && {
                [this.region]: credentials.token,
            })));
        }
        if (env_1.env.REDOCLY_AUTHORIZATION) {
            this.setAccessTokens(Object.assign(Object.assign({}, this.accessTokens), { [this.region]: env_1.env.REDOCLY_AUTHORIZATION }));
        }
    }
    getAllTokens() {
        return Object.entries(this.accessTokens)
            .filter(([region]) => config_1.AVAILABLE_REGIONS.includes(region))
            .map(([region, token]) => ({ region, token }));
    }
    getValidTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            const allTokens = this.getAllTokens();
            const verifiedTokens = yield Promise.allSettled(allTokens.map(({ token, region }) => this.verifyToken(token, region)));
            return allTokens
                .filter((_, index) => verifiedTokens[index].status === 'fulfilled')
                .map(({ token, region }) => ({ token, region, valid: true }));
        });
    }
    getTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.hasTokens() ? yield this.getValidTokens() : [];
        });
    }
    isAuthorizedWithRedoclyByRegion() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hasTokens()) {
                return false;
            }
            const accessToken = this.accessTokens[this.region];
            if (!accessToken) {
                return false;
            }
            try {
                yield this.verifyToken(accessToken, this.region);
                return true;
            }
            catch (err) {
                return false;
            }
        });
    }
    isAuthorizedWithRedocly() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.hasTokens() && (0, utils_1.isNotEmptyObject)(yield this.getValidTokens());
        });
    }
    readCredentialsFile(credentialsPath) {
        return (0, fs_1.existsSync)(credentialsPath) ? JSON.parse((0, fs_1.readFileSync)(credentialsPath, 'utf-8')) : {};
    }
    verifyToken(accessToken, region, verbose = false) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.registryApi.authStatus(accessToken, region, verbose);
        });
    }
    login(accessToken, verbose = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const credentialsPath = (0, path_1.resolve)((0, os_1.homedir)(), exports.TOKEN_FILENAME);
            try {
                yield this.verifyToken(accessToken, this.region, verbose);
            }
            catch (err) {
                throw new Error('Authorization failed. Please check if you entered a valid API key.');
            }
            const credentials = Object.assign(Object.assign({}, this.readCredentialsFile(credentialsPath)), { [this.region]: accessToken, token: accessToken });
            this.accessTokens = credentials;
            this.registryApi.setAccessTokens(credentials);
            (0, fs_1.writeFileSync)(credentialsPath, JSON.stringify(credentials, null, 2));
        });
    }
    logout() {
        const credentialsPath = (0, path_1.resolve)((0, os_1.homedir)(), exports.TOKEN_FILENAME);
        if ((0, fs_1.existsSync)(credentialsPath)) {
            (0, fs_1.unlinkSync)(credentialsPath);
        }
    }
}
exports.RedoclyClient = RedoclyClient;
function isRedoclyRegistryURL(link) {
    const domain = env_1.env.REDOCLY_DOMAIN || config_1.DOMAINS[config_1.DEFAULT_REGION];
    const legacyDomain = domain === 'redocly.com' ? 'redoc.ly' : domain;
    if (!link.startsWith(`https://api.${domain}/registry/`) &&
        !link.startsWith(`https://api.${legacyDomain}/registry/`)) {
        return false;
    }
    return true;
}
exports.isRedoclyRegistryURL = isRedoclyRegistryURL;
