"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigValidationError = exports.getUniquePlugins = exports.getResolveConfig = exports.transformConfig = exports.checkForDeprecatedFields = exports.getMergedConfig = exports.mergeExtends = exports.prefixRules = exports.transformApiDefinitionsToApis = exports.parsePresetName = void 0;
const utils_1 = require("../utils");
const config_1 = require("./config");
const logger_1 = require("../logger");
function parsePresetName(presetName) {
    if (presetName.indexOf('/') > -1) {
        const [pluginId, configName] = presetName.split('/');
        return { pluginId, configName };
    }
    else {
        return { pluginId: '', configName: presetName };
    }
}
exports.parsePresetName = parsePresetName;
function transformApiDefinitionsToApis(apiDefinitions) {
    if (!apiDefinitions)
        return undefined;
    const apis = {};
    for (const [apiName, apiPath] of Object.entries(apiDefinitions)) {
        apis[apiName] = { root: apiPath };
    }
    return apis;
}
exports.transformApiDefinitionsToApis = transformApiDefinitionsToApis;
function extractFlatConfig(_a) {
    var _b;
    var { plugins, extends: _extends, rules, oas2Rules, oas3_0Rules, oas3_1Rules, async2Rules, preprocessors, oas2Preprocessors, oas3_0Preprocessors, oas3_1Preprocessors, async2Preprocessors, decorators, oas2Decorators, oas3_0Decorators, oas3_1Decorators, async2Decorators } = _a, rawConfigRest = __rest(_a, ["plugins", "extends", "rules", "oas2Rules", "oas3_0Rules", "oas3_1Rules", "async2Rules", "preprocessors", "oas2Preprocessors", "oas3_0Preprocessors", "oas3_1Preprocessors", "async2Preprocessors", "decorators", "oas2Decorators", "oas3_0Decorators", "oas3_1Decorators", "async2Decorators"]);
    const styleguideConfig = {
        plugins,
        extends: _extends,
        rules,
        oas2Rules,
        oas3_0Rules,
        oas3_1Rules,
        async2Rules,
        preprocessors,
        oas2Preprocessors,
        oas3_0Preprocessors,
        oas3_1Preprocessors,
        async2Preprocessors,
        decorators,
        oas2Decorators,
        oas3_0Decorators,
        oas3_1Decorators,
        async2Decorators,
        doNotResolveExamples: (_b = rawConfigRest.resolve) === null || _b === void 0 ? void 0 : _b.doNotResolveExamples,
    };
    if ((rawConfigRest.lint && rawConfigRest.styleguide) ||
        (Object.values(styleguideConfig).some(utils_1.isDefined) &&
            (rawConfigRest.lint || rawConfigRest.styleguide))) {
        throw new Error(`Do not use 'lint', 'styleguide' and flat syntax together. \nSee more about the configuration in the docs: https://redocly.com/docs/cli/configuration/ \n`);
    }
    return {
        styleguideConfig: Object.values(styleguideConfig).some(utils_1.isDefined)
            ? styleguideConfig
            : undefined,
        rawConfigRest,
    };
}
function transformApis(legacyApis) {
    if (!legacyApis)
        return undefined;
    const apis = {};
    for (let _a of Object.entries(legacyApis)) {
        const [apiName, _b] = _a, { lint } = _b, apiContent = __rest(_b, ["lint"]);
        const { styleguideConfig, rawConfigRest } = extractFlatConfig(apiContent);
        apis[apiName] = Object.assign({ styleguide: styleguideConfig || lint }, rawConfigRest);
    }
    return apis;
}
function prefixRules(rules, prefix) {
    if (!prefix)
        return rules;
    const res = {};
    for (const name of Object.keys(rules)) {
        res[`${prefix}/${name}`] = rules[name];
    }
    return res;
}
exports.prefixRules = prefixRules;
function mergeExtends(rulesConfList) {
    const result = {
        rules: {},
        oas2Rules: {},
        oas3_0Rules: {},
        oas3_1Rules: {},
        async2Rules: {},
        preprocessors: {},
        oas2Preprocessors: {},
        oas3_0Preprocessors: {},
        oas3_1Preprocessors: {},
        async2Preprocessors: {},
        decorators: {},
        oas2Decorators: {},
        oas3_0Decorators: {},
        oas3_1Decorators: {},
        async2Decorators: {},
        plugins: [],
        pluginPaths: [],
        extendPaths: [],
    };
    for (const rulesConf of rulesConfList) {
        if (rulesConf.extends) {
            throw new Error(`'extends' is not supported in shared configs yet:\n${JSON.stringify(rulesConf, null, 2)}`);
        }
        Object.assign(result.rules, rulesConf.rules);
        Object.assign(result.oas2Rules, rulesConf.oas2Rules);
        (0, utils_1.assignExisting)(result.oas2Rules, rulesConf.rules || {});
        Object.assign(result.oas3_0Rules, rulesConf.oas3_0Rules);
        (0, utils_1.assignExisting)(result.oas3_0Rules, rulesConf.rules || {});
        Object.assign(result.oas3_1Rules, rulesConf.oas3_1Rules);
        (0, utils_1.assignExisting)(result.oas3_1Rules, rulesConf.rules || {});
        Object.assign(result.async2Rules, rulesConf.async2Rules);
        (0, utils_1.assignExisting)(result.async2Rules, rulesConf.rules || {});
        Object.assign(result.preprocessors, rulesConf.preprocessors);
        Object.assign(result.oas2Preprocessors, rulesConf.oas2Preprocessors);
        (0, utils_1.assignExisting)(result.oas2Preprocessors, rulesConf.preprocessors || {});
        Object.assign(result.oas3_0Preprocessors, rulesConf.oas3_0Preprocessors);
        (0, utils_1.assignExisting)(result.oas3_0Preprocessors, rulesConf.preprocessors || {});
        Object.assign(result.oas3_1Preprocessors, rulesConf.oas3_1Preprocessors);
        (0, utils_1.assignExisting)(result.oas3_1Preprocessors, rulesConf.preprocessors || {});
        Object.assign(result.async2Preprocessors, rulesConf.async2Preprocessors);
        (0, utils_1.assignExisting)(result.async2Preprocessors, rulesConf.preprocessors || {});
        Object.assign(result.decorators, rulesConf.decorators);
        Object.assign(result.oas2Decorators, rulesConf.oas2Decorators);
        (0, utils_1.assignExisting)(result.oas2Decorators, rulesConf.decorators || {});
        Object.assign(result.oas3_0Decorators, rulesConf.oas3_0Decorators);
        (0, utils_1.assignExisting)(result.oas3_0Decorators, rulesConf.decorators || {});
        Object.assign(result.oas3_1Decorators, rulesConf.oas3_1Decorators);
        (0, utils_1.assignExisting)(result.oas3_1Decorators, rulesConf.decorators || {});
        Object.assign(result.async2Decorators, rulesConf.async2Decorators);
        (0, utils_1.assignExisting)(result.async2Decorators, rulesConf.decorators || {});
        result.plugins.push(...(rulesConf.plugins || []));
        result.pluginPaths.push(...(rulesConf.pluginPaths || []));
        result.extendPaths.push(...new Set(rulesConf.extendPaths));
    }
    return result;
}
exports.mergeExtends = mergeExtends;
function getMergedConfig(config, apiName) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const extendPaths = [
        ...Object.values(config.apis).map((api) => { var _a; return (_a = api === null || api === void 0 ? void 0 : api.styleguide) === null || _a === void 0 ? void 0 : _a.extendPaths; }),
        (_b = (_a = config.rawConfig) === null || _a === void 0 ? void 0 : _a.styleguide) === null || _b === void 0 ? void 0 : _b.extendPaths,
    ]
        .flat()
        .filter(utils_1.isTruthy);
    const pluginPaths = [
        ...Object.values(config.apis).map((api) => { var _a; return (_a = api === null || api === void 0 ? void 0 : api.styleguide) === null || _a === void 0 ? void 0 : _a.pluginPaths; }),
        (_d = (_c = config.rawConfig) === null || _c === void 0 ? void 0 : _c.styleguide) === null || _d === void 0 ? void 0 : _d.pluginPaths,
    ]
        .flat()
        .filter(utils_1.isTruthy);
    return apiName
        ? new config_1.Config(Object.assign(Object.assign({}, config.rawConfig), { styleguide: Object.assign(Object.assign({}, (config.apis[apiName]
                ? config.apis[apiName].styleguide
                : config.rawConfig.styleguide)), { extendPaths,
                pluginPaths }), theme: Object.assign(Object.assign({}, config.rawConfig.theme), (_e = config.apis[apiName]) === null || _e === void 0 ? void 0 : _e.theme), files: [...config.files, ...((_h = (_g = (_f = config.apis) === null || _f === void 0 ? void 0 : _f[apiName]) === null || _g === void 0 ? void 0 : _g.files) !== null && _h !== void 0 ? _h : [])] }), config.configFile)
        : config;
}
exports.getMergedConfig = getMergedConfig;
function checkForDeprecatedFields(deprecatedField, updatedField, rawConfig, updatedObject) {
    const isDeprecatedFieldInApis = rawConfig.apis &&
        Object.values(rawConfig.apis).some((api) => api[deprecatedField]);
    if (rawConfig[deprecatedField] && updatedField === null) {
        (0, utils_1.showWarningForDeprecatedField)(deprecatedField);
    }
    if (rawConfig[deprecatedField] && updatedField && rawConfig[updatedField]) {
        (0, utils_1.showErrorForDeprecatedField)(deprecatedField, updatedField);
    }
    if (rawConfig[deprecatedField] && updatedObject && rawConfig[updatedObject]) {
        (0, utils_1.showErrorForDeprecatedField)(deprecatedField, updatedField, updatedObject);
    }
    if (rawConfig[deprecatedField] || isDeprecatedFieldInApis) {
        (0, utils_1.showWarningForDeprecatedField)(deprecatedField, updatedField, updatedObject);
    }
}
exports.checkForDeprecatedFields = checkForDeprecatedFields;
function transformConfig(rawConfig) {
    var _a, _b;
    const migratedFields = [
        ['apiDefinitions', 'apis', undefined],
        ['referenceDocs', 'openapi', 'theme'],
        ['lint', undefined, undefined],
        ['styleguide', undefined, undefined],
        ['features.openapi', 'openapi', 'theme'],
    ];
    for (const [deprecatedField, updatedField, updatedObject] of migratedFields) {
        checkForDeprecatedFields(deprecatedField, updatedField, rawConfig, updatedObject);
    }
    const { apis, apiDefinitions, referenceDocs, lint } = rawConfig, rest = __rest(rawConfig, ["apis", "apiDefinitions", "referenceDocs", "lint"]);
    const { styleguideConfig, rawConfigRest } = extractFlatConfig(rest);
    const transformedConfig = Object.assign({ theme: {
            openapi: Object.assign(Object.assign(Object.assign({}, referenceDocs), rawConfig['features.openapi']), (_a = rawConfig.theme) === null || _a === void 0 ? void 0 : _a.openapi),
            mockServer: Object.assign(Object.assign({}, rawConfig['features.mockServer']), (_b = rawConfig.theme) === null || _b === void 0 ? void 0 : _b.mockServer),
        }, apis: transformApis(apis) || transformApiDefinitionsToApis(apiDefinitions), styleguide: styleguideConfig || lint }, rawConfigRest);
    showDeprecationMessages(transformedConfig);
    return transformedConfig;
}
exports.transformConfig = transformConfig;
function showDeprecationMessages(config) {
    var _a, _b;
    let allRules = Object.assign({}, (_a = config.styleguide) === null || _a === void 0 ? void 0 : _a.rules);
    for (const api of Object.values(config.apis || {})) {
        allRules = Object.assign(Object.assign({}, allRules), (_b = api === null || api === void 0 ? void 0 : api.styleguide) === null || _b === void 0 ? void 0 : _b.rules);
    }
    for (const ruleKey of Object.keys(allRules)) {
        if (ruleKey.startsWith('assert/')) {
            logger_1.logger.warn(`\nThe 'assert/' syntax in ${ruleKey} is deprecated. Update your configuration to use 'rule/' instead. Examples and more information: https://redocly.com/docs/cli/rules/configurable-rules/\n`);
        }
    }
}
function getResolveConfig(resolve) {
    var _a, _b;
    return {
        http: {
            headers: (_b = (_a = resolve === null || resolve === void 0 ? void 0 : resolve.http) === null || _a === void 0 ? void 0 : _a.headers) !== null && _b !== void 0 ? _b : [],
            customFetch: undefined,
        },
    };
}
exports.getResolveConfig = getResolveConfig;
function getUniquePlugins(plugins) {
    const seen = new Set();
    const results = [];
    for (const p of plugins) {
        if (!seen.has(p.id)) {
            results.push(p);
            seen.add(p.id);
        }
        else if (p.id) {
            logger_1.logger.warn(`Duplicate plugin id "${logger_1.colorize.red(p.id)}".\n`);
        }
    }
    return results;
}
exports.getUniquePlugins = getUniquePlugins;
class ConfigValidationError extends Error {
}
exports.ConfigValidationError = ConfigValidationError;
