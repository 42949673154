"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistryApi = void 0;
const node_fetch_1 = require("node-fetch");
const config_1 = require("../config/config");
const utils_1 = require("../utils");
const version = require('../../package.json').version;
class RegistryApi {
    constructor(accessTokens, region) {
        this.accessTokens = accessTokens;
        this.region = region;
    }
    get accessToken() {
        return (0, utils_1.isNotEmptyObject)(this.accessTokens) && this.accessTokens[this.region];
    }
    getBaseUrl(region = config_1.DEFAULT_REGION) {
        return `https://api.${config_1.DOMAINS[region]}/registry`;
    }
    setAccessTokens(accessTokens) {
        this.accessTokens = accessTokens;
        return this;
    }
    request(path = '', options = {}, region) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const currentCommand = typeof process !== 'undefined' ? ((_a = process.env) === null || _a === void 0 ? void 0 : _a.REDOCLY_CLI_COMMAND) || '' : '';
            const redoclyEnv = typeof process !== 'undefined' ? ((_b = process.env) === null || _b === void 0 ? void 0 : _b.REDOCLY_ENVIRONMENT) || '' : '';
            const headers = Object.assign({}, options.headers || {}, {
                'x-redocly-cli-version': version,
                'user-agent': `redocly-cli / ${version} ${currentCommand} ${redoclyEnv}`,
            });
            if (!headers.hasOwnProperty('authorization')) {
                throw new Error('Unauthorized');
            }
            const response = yield (0, node_fetch_1.default)(`${this.getBaseUrl(region)}${path}`, Object.assign({}, options, { headers }));
            if (response.status === 401) {
                throw new Error('Unauthorized');
            }
            if (response.status === 404) {
                const body = yield response.json();
                throw new Error(body.code);
            }
            return response;
        });
    }
    authStatus(accessToken, region, verbose = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.request('', { headers: { authorization: accessToken } }, region);
                return yield response.json();
            }
            catch (error) {
                if (verbose) {
                    console.log(error);
                }
                throw error;
            }
        });
    }
    prepareFileUpload({ organizationId, name, version, filesHash, filename, isUpsert, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.request(`/${organizationId}/${name}/${version}/prepare-file-upload`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    authorization: this.accessToken,
                },
                body: JSON.stringify({
                    filesHash,
                    filename,
                    isUpsert,
                }),
            }, this.region);
            if (response.ok) {
                return response.json();
            }
            throw new Error('Could not prepare file upload');
        });
    }
    pushApi({ organizationId, name, version, rootFilePath, filePaths, branch, isUpsert, isPublic, batchId, batchSize, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.request(`/${organizationId}/${name}/${version}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    authorization: this.accessToken,
                },
                body: JSON.stringify({
                    rootFilePath,
                    filePaths,
                    branch,
                    isUpsert,
                    isPublic,
                    batchId,
                    batchSize,
                }),
            }, this.region);
            if (response.ok) {
                return;
            }
            throw new Error('Could not push api');
        });
    }
}
exports.RegistryApi = RegistryApi;
