"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = exports.StyleguideConfig = exports.AVAILABLE_REGIONS = exports.DOMAINS = exports.DEFAULT_REGION = exports.IGNORE_FILE = void 0;
const fs = require("fs");
const path = require("path");
const js_yaml_1 = require("../js-yaml");
const utils_1 = require("../utils");
const oas_types_1 = require("../oas-types");
const env_1 = require("../env");
const utils_2 = require("./utils");
const ref_utils_1 = require("../ref-utils");
exports.IGNORE_FILE = '.redocly.lint-ignore.yaml';
const IGNORE_BANNER = `# This file instructs Redocly's linter to ignore the rules contained for specific parts of your API.\n` +
    `# See https://redoc.ly/docs/cli/ for more information.\n`;
exports.DEFAULT_REGION = 'us';
function getDomains() {
    const domains = {
        us: 'redocly.com',
        eu: 'eu.redocly.com',
    };
    // FIXME: temporary fix for our lab environments
    const domain = env_1.env.REDOCLY_DOMAIN;
    if (domain === null || domain === void 0 ? void 0 : domain.endsWith('.redocly.host')) {
        domains[domain.split('.')[0]] = domain;
    }
    if (domain === 'redoc.online') {
        domains[domain] = domain;
    }
    return domains;
}
function getIgnoreFilePath(configFile) {
    if (configFile) {
        return (0, utils_1.doesYamlFileExist)(configFile)
            ? path.join(path.dirname(configFile), exports.IGNORE_FILE)
            : path.join(configFile, exports.IGNORE_FILE);
    }
    else {
        return env_1.isBrowser ? undefined : path.join(process.cwd(), exports.IGNORE_FILE);
    }
}
exports.DOMAINS = getDomains();
exports.AVAILABLE_REGIONS = Object.keys(exports.DOMAINS);
class StyleguideConfig {
    constructor(rawConfig, configFile) {
        this.rawConfig = rawConfig;
        this.configFile = configFile;
        this.ignore = {};
        this._usedRules = new Set();
        this._usedVersions = new Set();
        this.plugins = rawConfig.plugins || [];
        this.doNotResolveExamples = !!rawConfig.doNotResolveExamples;
        this.recommendedFallback = rawConfig.recommendedFallback || false;
        this.rules = {
            [oas_types_1.SpecVersion.OAS2]: Object.assign(Object.assign({}, rawConfig.rules), rawConfig.oas2Rules),
            [oas_types_1.SpecVersion.OAS3_0]: Object.assign(Object.assign({}, rawConfig.rules), rawConfig.oas3_0Rules),
            [oas_types_1.SpecVersion.OAS3_1]: Object.assign(Object.assign({}, rawConfig.rules), rawConfig.oas3_1Rules),
            [oas_types_1.SpecVersion.Async2]: Object.assign(Object.assign({}, rawConfig.rules), rawConfig.async2Rules),
        };
        this.preprocessors = {
            [oas_types_1.SpecVersion.OAS2]: Object.assign(Object.assign({}, rawConfig.preprocessors), rawConfig.oas2Preprocessors),
            [oas_types_1.SpecVersion.OAS3_0]: Object.assign(Object.assign({}, rawConfig.preprocessors), rawConfig.oas3_0Preprocessors),
            [oas_types_1.SpecVersion.OAS3_1]: Object.assign(Object.assign({}, rawConfig.preprocessors), rawConfig.oas3_1Preprocessors),
            [oas_types_1.SpecVersion.Async2]: Object.assign(Object.assign({}, rawConfig.preprocessors), rawConfig.async2Preprocessors),
        };
        this.decorators = {
            [oas_types_1.SpecVersion.OAS2]: Object.assign(Object.assign({}, rawConfig.decorators), rawConfig.oas2Decorators),
            [oas_types_1.SpecVersion.OAS3_0]: Object.assign(Object.assign({}, rawConfig.decorators), rawConfig.oas3_0Decorators),
            [oas_types_1.SpecVersion.OAS3_1]: Object.assign(Object.assign({}, rawConfig.decorators), rawConfig.oas3_1Decorators),
            [oas_types_1.SpecVersion.Async2]: Object.assign(Object.assign({}, rawConfig.decorators), rawConfig.async2Decorators),
        };
        this.extendPaths = rawConfig.extendPaths || [];
        this.pluginPaths = rawConfig.pluginPaths || [];
        this.resolveIgnore(getIgnoreFilePath(configFile));
    }
    resolveIgnore(ignoreFile) {
        if (!ignoreFile || !(0, utils_1.doesYamlFileExist)(ignoreFile))
            return;
        this.ignore =
            (0, js_yaml_1.parseYaml)(fs.readFileSync(ignoreFile, 'utf-8')) || {};
        // resolve ignore paths
        for (const fileName of Object.keys(this.ignore)) {
            this.ignore[(0, ref_utils_1.isAbsoluteUrl)(fileName) ? fileName : path.resolve(path.dirname(ignoreFile), fileName)] = this.ignore[fileName];
            for (const ruleId of Object.keys(this.ignore[fileName])) {
                this.ignore[fileName][ruleId] = new Set(this.ignore[fileName][ruleId]);
            }
            if (!(0, ref_utils_1.isAbsoluteUrl)(fileName)) {
                delete this.ignore[fileName];
            }
        }
    }
    saveIgnore() {
        const dir = this.configFile ? path.dirname(this.configFile) : process.cwd();
        const ignoreFile = path.join(dir, exports.IGNORE_FILE);
        const mapped = {};
        for (const absFileName of Object.keys(this.ignore)) {
            const mappedDefinitionName = (0, ref_utils_1.isAbsoluteUrl)(absFileName)
                ? absFileName
                : (0, utils_1.slash)(path.relative(dir, absFileName));
            const ignoredRules = (mapped[mappedDefinitionName] = this.ignore[absFileName]);
            for (const ruleId of Object.keys(ignoredRules)) {
                ignoredRules[ruleId] = Array.from(ignoredRules[ruleId]);
            }
        }
        fs.writeFileSync(ignoreFile, IGNORE_BANNER + (0, js_yaml_1.stringifyYaml)(mapped));
    }
    addIgnore(problem) {
        const ignore = this.ignore;
        const loc = problem.location[0];
        if (loc.pointer === undefined)
            return;
        const fileIgnore = (ignore[loc.source.absoluteRef] = ignore[loc.source.absoluteRef] || {});
        const ruleIgnore = (fileIgnore[problem.ruleId] = fileIgnore[problem.ruleId] || new Set());
        ruleIgnore.add(loc.pointer);
    }
    addProblemToIgnore(problem) {
        const loc = problem.location[0];
        if (loc.pointer === undefined)
            return problem;
        const fileIgnore = this.ignore[loc.source.absoluteRef] || {};
        const ruleIgnore = fileIgnore[problem.ruleId];
        const ignored = ruleIgnore && ruleIgnore.has(loc.pointer);
        return ignored
            ? Object.assign(Object.assign({}, problem), { ignored }) : problem;
    }
    extendTypes(types, version) {
        let extendedTypes = types;
        for (const plugin of this.plugins) {
            if (plugin.typeExtension !== undefined) {
                switch (version) {
                    case oas_types_1.SpecVersion.OAS3_0:
                    case oas_types_1.SpecVersion.OAS3_1:
                        if (!plugin.typeExtension.oas3)
                            continue;
                        extendedTypes = plugin.typeExtension.oas3(extendedTypes, version);
                        break;
                    case oas_types_1.SpecVersion.OAS2:
                        if (!plugin.typeExtension.oas2)
                            continue;
                        extendedTypes = plugin.typeExtension.oas2(extendedTypes, version);
                        break;
                    case oas_types_1.SpecVersion.Async2:
                        if (!plugin.typeExtension.async2)
                            continue;
                        extendedTypes = plugin.typeExtension.async2(extendedTypes, version);
                        break;
                    default:
                        throw new Error('Not implemented');
                }
            }
        }
        return extendedTypes;
    }
    getRuleSettings(ruleId, oasVersion) {
        this._usedRules.add(ruleId);
        this._usedVersions.add(oasVersion);
        const settings = this.rules[oasVersion][ruleId] || 'off';
        if (typeof settings === 'string') {
            return {
                severity: settings,
            };
        }
        else {
            return Object.assign({ severity: 'error' }, settings);
        }
    }
    getPreprocessorSettings(ruleId, oasVersion) {
        this._usedRules.add(ruleId);
        this._usedVersions.add(oasVersion);
        const settings = this.preprocessors[oasVersion][ruleId] || 'off';
        if (typeof settings === 'string') {
            return {
                severity: settings === 'on' ? 'error' : settings,
            };
        }
        else {
            return Object.assign({ severity: 'error' }, settings);
        }
    }
    getDecoratorSettings(ruleId, oasVersion) {
        this._usedRules.add(ruleId);
        this._usedVersions.add(oasVersion);
        const settings = this.decorators[oasVersion][ruleId] || 'off';
        if (typeof settings === 'string') {
            return {
                severity: settings === 'on' ? 'error' : settings,
            };
        }
        else {
            return Object.assign({ severity: 'error' }, settings);
        }
    }
    getUnusedRules() {
        const rules = [];
        const decorators = [];
        const preprocessors = [];
        for (const usedVersion of Array.from(this._usedVersions)) {
            rules.push(...Object.keys(this.rules[usedVersion]).filter((name) => !this._usedRules.has(name)));
            decorators.push(...Object.keys(this.decorators[usedVersion]).filter((name) => !this._usedRules.has(name)));
            preprocessors.push(...Object.keys(this.preprocessors[usedVersion]).filter((name) => !this._usedRules.has(name)));
        }
        return {
            rules,
            preprocessors,
            decorators,
        };
    }
    getRulesForOasVersion(version) {
        switch (version) {
            case oas_types_1.SpecMajorVersion.OAS3:
                // eslint-disable-next-line no-case-declarations
                const oas3Rules = []; // default ruleset
                this.plugins.forEach((p) => { var _a; return ((_a = p.preprocessors) === null || _a === void 0 ? void 0 : _a.oas3) && oas3Rules.push(p.preprocessors.oas3); });
                this.plugins.forEach((p) => { var _a; return ((_a = p.rules) === null || _a === void 0 ? void 0 : _a.oas3) && oas3Rules.push(p.rules.oas3); });
                this.plugins.forEach((p) => { var _a; return ((_a = p.decorators) === null || _a === void 0 ? void 0 : _a.oas3) && oas3Rules.push(p.decorators.oas3); });
                return oas3Rules;
            case oas_types_1.SpecMajorVersion.OAS2:
                // eslint-disable-next-line no-case-declarations
                const oas2Rules = []; // default ruleset
                this.plugins.forEach((p) => { var _a; return ((_a = p.preprocessors) === null || _a === void 0 ? void 0 : _a.oas2) && oas2Rules.push(p.preprocessors.oas2); });
                this.plugins.forEach((p) => { var _a; return ((_a = p.rules) === null || _a === void 0 ? void 0 : _a.oas2) && oas2Rules.push(p.rules.oas2); });
                this.plugins.forEach((p) => { var _a; return ((_a = p.decorators) === null || _a === void 0 ? void 0 : _a.oas2) && oas2Rules.push(p.decorators.oas2); });
                return oas2Rules;
            case oas_types_1.SpecMajorVersion.Async2:
                // eslint-disable-next-line no-case-declarations
                const asyncApiRules = []; // default ruleset
                this.plugins.forEach((p) => { var _a; return ((_a = p.preprocessors) === null || _a === void 0 ? void 0 : _a.async2) && asyncApiRules.push(p.preprocessors.async2); });
                this.plugins.forEach((p) => { var _a; return ((_a = p.rules) === null || _a === void 0 ? void 0 : _a.async2) && asyncApiRules.push(p.rules.async2); });
                this.plugins.forEach((p) => { var _a; return ((_a = p.decorators) === null || _a === void 0 ? void 0 : _a.async2) && asyncApiRules.push(p.decorators.async2); });
                return asyncApiRules;
        }
    }
    skipRules(rules) {
        for (const ruleId of rules || []) {
            for (const version of Object.values(oas_types_1.SpecVersion)) {
                if (this.rules[version][ruleId]) {
                    this.rules[version][ruleId] = 'off';
                }
            }
        }
    }
    skipPreprocessors(preprocessors) {
        for (const preprocessorId of preprocessors || []) {
            for (const version of Object.values(oas_types_1.SpecVersion)) {
                if (this.preprocessors[version][preprocessorId]) {
                    this.preprocessors[version][preprocessorId] = 'off';
                }
            }
        }
    }
    skipDecorators(decorators) {
        for (const decoratorId of decorators || []) {
            for (const version of Object.values(oas_types_1.SpecVersion)) {
                if (this.decorators[version][decoratorId]) {
                    this.decorators[version][decoratorId] = 'off';
                }
            }
        }
    }
}
exports.StyleguideConfig = StyleguideConfig;
class Config {
    constructor(rawConfig, configFile) {
        this.rawConfig = rawConfig;
        this.configFile = configFile;
        this.apis = rawConfig.apis || {};
        this.styleguide = new StyleguideConfig(rawConfig.styleguide || {}, configFile);
        this.theme = rawConfig.theme || {};
        this.resolve = (0, utils_2.getResolveConfig)(rawConfig === null || rawConfig === void 0 ? void 0 : rawConfig.resolve);
        this.region = rawConfig.region;
        this.organization = rawConfig.organization;
        this.files = rawConfig.files || [];
        this.telemetry = rawConfig.telemetry;
    }
}
exports.Config = Config;
