{
  "$schema": "https://json-schema.org/draft/2020-12/schema",
  "$id": "https://json-schema.org/draft/2020-12/meta/content",
  "$vocabulary": {
    "https://json-schema.org/draft/2020-12/vocab/content": true
  },
  "$dynamicAnchor": "meta",

  "title": "Content vocabulary meta-schema",

  "type": ["object", "boolean"],
  "properties": {
    "contentEncoding": {"type": "string"},
    "contentMediaType": {"type": "string"},
    "contentSchema": {"$dynamicRef": "#meta"}
  }
}
