export const DEFAULT_TEAM_CLAIM_NAME = 'https://redocly.com/sso/teams';
export enum AuthProviderType {
  OIDC = 'OIDC',
  SAML2 = 'SAML2',
  BASIC = 'BASIC',
}
export enum ApigeeDevOnboardingIntegrationAuthType {
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  OAUTH2 = 'OAUTH2',
}

export const REDOCLY_TEAMS_RBAC = 'redocly::teams-rbac';
export const REDOCLY_ROUTE_RBAC = 'redocly::route-rbac';

export enum LayoutVariant {
  STACKED = 'stacked',
  THREE_PANEL = 'three-panel',
}
