"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.env = exports.isBrowser = void 0;
exports.isBrowser = 
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
typeof window !== 'undefined' ||
    typeof process === 'undefined' ||
    (process === null || process === void 0 ? void 0 : process.platform) === 'browser'; // main and worker thread
exports.env = exports.isBrowser ? {} : process.env || {};
