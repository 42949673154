import type { FromSchema } from 'json-schema-to-ts';
import type {
  amplitudeAnalyticsConfigSchema,
  catalogFilterSchema,
  catalogSchema,
  googleAnalyticsConfigSchema,
  gtmAnalyticsConfigSchema,
  markdownConfigSchema,
  productConfigSchema,
  productGoogleAnalyticsConfigSchema,
  rudderstackAnalyticsConfigSchema,
  scorecardConfigSchema,
  segmentAnalyticsConfigSchema,
  themeConfigSchema,
} from './default-theme-config-schema';
import type {
  apiConfigSchema,
  apigeeAdapterAuthOauth2Schema,
  apigeeAdapterAuthServiceAccountSchema,
  apigeeEdgeAdapterConfigSchema,
  apigeeXAdapterConfigSchema,
  authProviderConfigSchema,
  basicAuthProviderConfigSchema,
  devOnboardingAdapterConfigSchema,
  graviteeAdapterConfigSchema,
  i18ConfigSchema,
  oidcIssuerMetadataSchema,
  oidcProviderConfigSchema,
  rbacConfigSchema,
  rbacScopeItemsSchema,
  redirectConfigSchema,
  redirectsConfigSchema,
  rootRedoclyConfigSchema,
  saml2ProviderConfigSchema,
  seoConfigSchema,
  ssoOnPremConfigSchema,
} from './root-config-schema';

/**
 * Theme
 */

export type ThemeConfig = FromSchema<typeof themeConfigSchema>;
export type ProductConfig = FromSchema<typeof productConfigSchema>;
export type ProductGoogleAnalyticsConfig = FromSchema<typeof productGoogleAnalyticsConfigSchema>;
export type MarkdownConfig = FromSchema<typeof markdownConfigSchema>;
export type AmplitudeAnalyticsConfig = FromSchema<typeof amplitudeAnalyticsConfigSchema>;
export type RudderstackAnalyticsConfig = FromSchema<typeof rudderstackAnalyticsConfigSchema>;
export type SegmentAnalyticsConfig = FromSchema<typeof segmentAnalyticsConfigSchema>;
export type GtmAnalyticsConfig = FromSchema<typeof gtmAnalyticsConfigSchema>;
export type GoogleAnalyticsConfig = FromSchema<typeof googleAnalyticsConfigSchema>;
export type CatalogConfig = FromSchema<typeof catalogSchema>;
export type CatalogFilterConfig = FromSchema<typeof catalogFilterSchema>;
export type ScorecardConfig = FromSchema<typeof scorecardConfigSchema>;

/**
 * Root
 */

export type RedoclyConfig<T = ThemeConfig> = FromSchema<typeof rootRedoclyConfigSchema> & {
  theme?: T;
};
export type RedirectConfig = FromSchema<typeof redirectConfigSchema>;
export type RedirectsConfig = FromSchema<typeof redirectsConfigSchema>;
export type AuthProviderConfig = FromSchema<typeof authProviderConfigSchema>;
export type BasicAuthProviderConfig = FromSchema<typeof basicAuthProviderConfigSchema>;
export type OidcProviderConfig = FromSchema<typeof oidcProviderConfigSchema>;
export type Saml2ProviderConfig = FromSchema<typeof saml2ProviderConfigSchema>;
export type SeoConfig = FromSchema<typeof seoConfigSchema>;
export type RbacConfig = FromSchema<typeof rbacConfigSchema>;
export type RbacScopeItems = FromSchema<typeof rbacScopeItemsSchema>;
export type OidcIssuerMetadata = FromSchema<typeof oidcIssuerMetadataSchema>;
export type DevOnboardingAdapterConfig = FromSchema<typeof devOnboardingAdapterConfigSchema>;
export type GraviteeAdapterConfig = FromSchema<typeof graviteeAdapterConfigSchema>;
export type ApigeeAdapterConfig = FromSchema<
  typeof apigeeXAdapterConfigSchema | typeof apigeeEdgeAdapterConfigSchema
>;
export type ApigeeAdapterAuthOauth2 = FromSchema<typeof apigeeAdapterAuthOauth2Schema>;
export type ApigeeAdapterAuthServiceAccount = FromSchema<
  typeof apigeeAdapterAuthServiceAccountSchema
>;
export type SsoConfig = FromSchema<typeof ssoOnPremConfigSchema>;
export type I18nConfig = FromSchema<typeof i18ConfigSchema>;
export type ApiConfig = FromSchema<typeof apiConfigSchema>;
