"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTypes = exports.getMajorSpecVersion = exports.detectSpec = exports.SpecMajorVersion = exports.SpecVersion = void 0;
const oas2_1 = require("./types/oas2");
const oas3_1 = require("./types/oas3");
const oas3_1_1 = require("./types/oas3_1");
const asyncapi_1 = require("./types/asyncapi");
var SpecVersion;
(function (SpecVersion) {
    SpecVersion["OAS2"] = "oas2";
    SpecVersion["OAS3_0"] = "oas3_0";
    SpecVersion["OAS3_1"] = "oas3_1";
    SpecVersion["Async2"] = "async2";
})(SpecVersion || (exports.SpecVersion = SpecVersion = {}));
var SpecMajorVersion;
(function (SpecMajorVersion) {
    SpecMajorVersion["OAS2"] = "oas2";
    SpecMajorVersion["OAS3"] = "oas3";
    SpecMajorVersion["Async2"] = "async2";
})(SpecMajorVersion || (exports.SpecMajorVersion = SpecMajorVersion = {}));
const typesMap = {
    [SpecVersion.OAS2]: oas2_1.Oas2Types,
    [SpecVersion.OAS3_0]: oas3_1.Oas3Types,
    [SpecVersion.OAS3_1]: oas3_1_1.Oas3_1Types,
    [SpecVersion.Async2]: asyncapi_1.AsyncApi2Types,
};
function detectSpec(root) {
    if (typeof root !== 'object') {
        throw new Error(`Document must be JSON object, got ${typeof root}`);
    }
    if (root.openapi && typeof root.openapi !== 'string') {
        throw new Error(`Invalid OpenAPI version: should be a string but got "${typeof root.openapi}"`);
    }
    if (root.openapi && root.openapi.startsWith('3.0')) {
        return SpecVersion.OAS3_0;
    }
    if (root.openapi && root.openapi.startsWith('3.1')) {
        return SpecVersion.OAS3_1;
    }
    if (root.swagger && root.swagger === '2.0') {
        return SpecVersion.OAS2;
    }
    // if not detected yet
    if (root.openapi || root.swagger) {
        throw new Error(`Unsupported OpenAPI version: ${root.openapi || root.swagger}`);
    }
    if (root.asyncapi && root.asyncapi.startsWith('2.')) {
        return SpecVersion.Async2;
    }
    if (root.asyncapi) {
        throw new Error(`Unsupported AsyncAPI version: ${root.asyncapi}`);
    }
    throw new Error(`Unsupported specification`);
}
exports.detectSpec = detectSpec;
function getMajorSpecVersion(version) {
    if (version === SpecVersion.OAS2) {
        return SpecMajorVersion.OAS2;
    }
    else if (version === SpecVersion.Async2) {
        return SpecMajorVersion.Async2;
    }
    else {
        return SpecMajorVersion.OAS3;
    }
}
exports.getMajorSpecVersion = getMajorSpecVersion;
function getTypes(spec) {
    return typesMap[spec];
}
exports.getTypes = getTypes;
