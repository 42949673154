export interface Doc {
	url: string;
	name: string;
}

export const docs: Doc[] = [
	{
		url: 'https://docs.dashboard-api.dev.eonhome.eu/swagger.json',
		name: 'Dashboard API (DEV)'
	},
	{
		url: 'https://docs.dashboard-api.qa.eonhome.eu/swagger.json',
		name: 'Dashboard API (QA)'
	},
	{
		url: 'https://docs.dashboard-api.qa.eonhome.eu/swagger.json',
		name: 'Dashboard API (PROD)'
	},
	{
		url: 'https://docs.sse-api.dev.eonhome.eu/swagger.json',
		name: 'SSE API (DEV)'
	},
	{
		url: 'https://docs.sse-api.qa.eonhome.eu/swagger.json',
		name: 'SSE API (QA)'
	},
	{
		url: 'https://docs.sse-api.qa.eonhome.eu/swagger.json',
		name: 'SSE API (PROD)'
	},
	{
		url: 'https://docs.admin-api.dev.eonhome.eu/swagger.json',
		name: 'Admin API (DEV)'
	},
	{
		url: 'https://docs.admin-api.qa.eonhome.eu/swagger.json',
		name: 'Admin API (QA)'
	},
	{
		url: 'https://docs.admin-api.qa.eonhome.eu/swagger.json',
		name: 'Admin API (PROD)'
	}
];
