{
  "name": "@redocly/openapi-core",
  "version": "1.12.0",
  "description": "",
  "main": "lib/index.js",
  "engines": {
    "node": ">=14.19.0",
    "npm": ">=7.0.0"
  },
  "engineStrict": true,
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "https://github.com/Redocly/redocly-cli.git"
  },
  "browser": {
    "fs": false,
    "path": "path-browserify",
    "os": false,
    "node-fetch": false,
    "colorette": false
  },
  "homepage": "https://github.com/Redocly/redocly-cli",
  "keywords": [
    "linter",
    "OpenAPI",
    "Swagger",
    "OpenAPI linter",
    "Swagger linter",
    "AsyncAPI linter",
    "oas"
  ],
  "contributors": [
    "Roman Hotsiy <roman@redoc.ly> (https://redoc.ly/)"
  ],
  "dependencies": {
    "@redocly/ajv": "^8.11.0",
    "@redocly/config": "^0.2.0",
    "colorette": "^1.2.0",
    "js-levenshtein": "^1.1.6",
    "js-yaml": "^4.1.0",
    "lodash.isequal": "^4.5.0",
    "minimatch": "^5.0.1",
    "node-fetch": "^2.6.1",
    "pluralize": "^8.0.0",
    "yaml-ast-parser": "0.0.43"
  },
  "devDependencies": {
    "@types/js-levenshtein": "^1.1.0",
    "@types/js-yaml": "^4.0.3",
    "@types/lodash.isequal": "^4.5.5",
    "@types/minimatch": "^3.0.5",
    "@types/node": "^20.11.5",
    "@types/node-fetch": "^2.5.7",
    "@types/pluralize": "^0.0.29",
    "typescript": "^5.2.2"
  }
}
